import classNames from 'classnames';
import { FC } from 'react';
import { useIsFetching } from 'react-query';

interface Props {
  children: React.ReactNode;
}

const PageWrapper: FC<Props> = ({ children }) => {
  const isFetching = useIsFetching();

  const showLoading = isFetching > 0;

  return (
    <div
      className={classNames('mx-auto px-4 sm:px-6 md:px-8 py-12', {
        'h-full': showLoading,
        'h-auto': !showLoading,
      })}
    >
      <div
        className={classNames('flex flex-col', {
          'h-full': showLoading,
          'h-auto': !showLoading,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default PageWrapper;
