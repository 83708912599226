import { Navigate } from 'react-router-dom';

const Callback = () => {
  const path = localStorage.getItem('authPath');
  localStorage.removeItem('authPath');

  return path ? <Navigate to={path} /> : null;
};

export default Callback;
