import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import React, { MouseEvent, useState } from 'react';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';
import { HTSColumn } from '../enums/HTSColumn';
import { IssuedColumn } from '../enums/IssuedColumn';
import { SupplierColumn } from '../enums/SupplierColumn';
import { IDataFiltersDTO } from '../interfaces/IDataFiltersDTO';
import { IDataItem } from '../interfaces/IDataItem';
import { IDataPayload } from '../interfaces/IDataPayload';
import { ISortDirection } from '../interfaces/ISortDirection';
import SummaryFooter from './data-table/SummaryFooter';
import { Pagination } from './Pagination';
import { BrokerColumn } from '../enums/BrokerColumn';
import { ReferralColumn } from '../enums/ReferralsColumn';
import { formatFields } from '../utils/formatting';
import { getEnumKey } from '../utils/enums';
import SpinningLoader from './Loader';

interface IDataTable {
  columns: (
    | SupplierColumn
    | HTSColumn
    | IssuedColumn
    | BrokerColumn
    | ReferralColumn
  )[];
  data?: IDataPayload<IDataItem>;
  status: any;
  error: any;
  isLoading: boolean;
  reportPage?: (page: number) => void;
  reportSort?: (sortBy: keyof IDataItem, sortDirection: ISortDirection) => void;
  page: number;
  filters?: IDataFiltersDTO;
  summaryView?: boolean;
  summaryViewTableName?: string;
  summaryViewLink?: string;
  hideExport?: boolean;
  isInternalTable?: boolean;
}

const DataTable: React.FC<IDataTable> = (props) => {
  const {
    columns,
    data: _data,
    reportPage,
    reportSort,
    page,
    filters,
    summaryView = false,
    summaryViewTableName,
    summaryViewLink,
    isLoading,
    hideExport = false,
    isInternalTable = false,
  } = props;

  const [sortDirection, setSortDirection] = useState<ISortDirection>('asc');
  const [sortColumn, setSortColumn] = useState<string>('');

  const isUrl = useMatch('/');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data, pagination } = _data || {};

  const handleItemClick = (item: any) => {
    const id = item?.uuid ? item.uuid : item.id;

    if (isUrl) {
      navigate(`${summaryViewLink}/${id}`);
    } else {
      navigate(`${pathname}/${id}`);
    }
  };

  const handleColumnClick = (event: MouseEvent, column: string) => {
    event.preventDefault();

    setSortColumn(column);

    reportSort?.(column, sortDirection);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  return isLoading ? (
    <div className="w-full h-full flex justify-center items-center">
      <SpinningLoader />
    </div>
  ) : (
    <div className={classNames('flex flex-col')}>
      <div className="overflow-x-auto w-full">
        <div className="align-middle inline-block min-w-full">
          <div className="overflow-hidden border-gray-200 sm:rounded-t-lg">
            <table className="min-w-full divide-y divide-gray-200 table-fixed">
              <thead className="bg-gray-50">
                <tr className="break-words">
                  {columns.map((column, index) => (
                    <th
                      key={`columns-${index}`}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 whitespace-nowrap"
                    >
                      <a
                        href="#"
                        className="group inline-flex items-center"
                        onClick={(event) => handleColumnClick(event, column)}
                      >
                        {column}
                        <span className="ml-2 flex-none rounded bg-gray-200 text-gray-900 group-hover:bg-gray-300">
                          {column === sortColumn && sortDirection === 'asc' ? (
                            <ChevronUpIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : column === sortColumn ? (
                            <ChevronDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          ) : null}
                        </span>
                      </a>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!!data?.length && !isLoading
                  ? data?.map((item: IDataItem, index: number) => (
                      <tr
                        onClick={() => handleItemClick(item)}
                        key={item.id}
                        className={classNames(
                          'hover:bg-gray-100 cursor-pointer',
                          {
                            'bg-white': index % 2 === 0,
                            'bg-gray-50': index % 2 !== 0,
                          }
                        )}
                      >
                        {columns.map((column, index) => {
                          const displayValue = formatFields(
                            column,
                            item[
                              isInternalTable
                                ? getEnumKey(ReferralColumn, column) || column
                                : column
                            ]
                          );

                          return (
                            <td
                              key={`value-${index}`}
                              className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 truncate max-w-lg sm:pl-6"
                            >
                              {displayValue?.toString()}
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  : Array.from(Array(20).keys()).map(
                      (item: number, index: number) => (
                        <tr
                          key={`loading-item-${item}`}
                          className={classNames(
                            'hover:bg-gray-100 cursor-pointer animate-pulse',
                            {
                              'bg-white': index % 2 === 0,
                              'bg-gray-50': index % 2 !== 0,
                            }
                          )}
                        >
                          {columns.map((column, index) => {
                            return (
                              <td
                                key={`value-${index}`}
                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 truncate max-w-lg h-12"
                              >
                                {' '}
                              </td>
                            );
                          })}
                        </tr>
                      )
                    )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {summaryView ? (
        <SummaryFooter
          tableName={summaryViewTableName}
          link={summaryViewLink}
        />
      ) : (
        <Pagination
          pagination={pagination}
          onNext={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            reportPage?.(page + 1);
          }}
          onPrevious={(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            reportPage?.(page !== 0 ? page - 1 : 0);
          }}
          filters={filters}
          className="shadow overflow-hidden border-b border-gray-200 sm:rounded-b-lg"
          hideExport={hideExport}
        />
      )}
    </div>
  );
};

export default DataTable;
