export enum BrokerColumn {
  ClientCode = 'Client Code',
  DatabaseCode = 'Database Code',
  PlantCode = 'Plant Code',
  PartNumber = 'Part Number',
  Description = 'Description',
  HTS = 'HTS',
  CountryOfOrigin = 'Country Of Origin',
  SupplierID = 'Supplier ID',
  SupplierName = 'Supplier Name',
  SPIEligibility = 'SPI Eligibility',
  SPI = 'SPI',
  EffectiveDate = 'Effective Date',
  ExpiryDate = 'Expiry Date',
  DateTransmitted = 'Date Transmitted',
}
