import axios from 'axios';
import { useQuery } from 'react-query';
import Http from '../services/http';

export function useUsers() {
  return useQuery<[]>(
    'users',
    async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/users`, {
        headers: {
          Authorization: `Bearer ${Http.accessToken}`,
        },
      });
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
}
