import axios from 'axios';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { useClients } from '../../hooks/useClients';
import Http from '../../services/http';
import * as Yup from 'yup';
import { useNotifications } from '../notifications/useNotifications';
import { ClientCodeMultiselect } from './ClientCodeMultiselect';

interface IInviteMutation {
  email: string;
  client_code: string[];
  user_type?: string;
}

const schema = Yup.object().shape({
  client_code: Yup.array().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  user_type: Yup.string().required('Required'),
});

const InviteUsers = () => {
  const { data: clients, isLoading } = useClients();
  const { pushNotification } = useNotifications();
  const client = useQueryClient();

  const mutation = useMutation<unknown, unknown, IInviteMutation>(
    (invite) => {
      return axios.post(`${process.env.REACT_APP_API}/invite`, invite, {
        headers: {
          Authorization: `Bearer ${Http.accessToken}`,
        },
      });
    },
    { onSuccess: () => client.invalidateQueries('users') }
  );

  return (
    <Formik
      initialValues={{
        email: '',
        client_code: [],
        user_type: 'standard',
      }}
      onSubmit={(values, { resetForm }) => {
        mutation.mutate({
          email: values?.email?.toLowerCase(),
          client_code: values?.client_code.filter(Boolean) as string[],
          user_type: values?.user_type,
        });

        pushNotification({
          heading: 'Invite Sent',
          content: `Portal invitation sent to ${values?.email}`,
        });

        resetForm();
      }}
      validateOnBlur
      validateOnMount
      validationSchema={schema}
    >
      {({ isSubmitting, errors, touched, isValid, values }) => {
        return (
          <Form>
            <div
              className={classNames('mt-5 md:mt-0 md:col-span-2', {
                'opacity-30': isSubmitting,
              })}
            >
              <fieldset disabled={isSubmitting}>
                <div className="shadow sm:rounded-md">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <h3 className="font-medium mb-4">Invite Users</h3>

                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-1">
                        <label
                          htmlFor="user-email"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Email
                        </label>
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                          placeholder="example@gmail.com"
                        />
                        {errors.email && touched.email ? (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            {errors.email}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <ClientCodeMultiselect
                          clients={clients}
                          disabled={!clients?.length}
                        />
                      </div>
                      <div className="col-span-3 sm:col-span-1">
                        <div>
                          <label
                            htmlFor="user_type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            User Type
                          </label>
                          <Field
                            as="select"
                            id="user_type"
                            name="user_type"
                            default="standard"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option
                              key={`user-type-key-admin`}
                              value="standard"
                            >
                              Standard
                            </option>
                            <option key={`user-type-key-broker`} value="broker">
                              Broker
                            </option>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      disabled={!isValid}
                      className={classNames(
                        'inline-flex justify-center py-2 px-4 focus:outline-none focus:ring-2 focus:ring-offset-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white',
                        {
                          'bg-indigo-600 hover:bg-indigo-700  focus:ring-indigo-500':
                            isValid,
                          'bg-indigo-300 hover:bg-indigo-300  focus:ring-indigo-300':
                            !isValid,
                        }
                      )}
                    >
                      Invite
                    </button>
                  </div>
                </div>
              </fieldset>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default InviteUsers;
