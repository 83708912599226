export function getConfig() {
  const { REACT_APP_DOMAIN, REACT_APP_CLIENT_ID, REACT_APP_AUDIENCE } =
    process.env;

  return {
    domain: REACT_APP_DOMAIN,
    clientId: REACT_APP_CLIENT_ID,
    audience: REACT_APP_AUDIENCE,
  };
}
