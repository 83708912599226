import DataTable from '../components/DataTable';
import HTSDataTableFilter from '../components/data-table/HTSDataTableFilter';
import Heading from '../components/Heading';
import { HTSColumn } from '../enums/HTSColumn';
import { useData } from '../hooks/useData';
import { IHTS } from '../interfaces/IHTS';
import { IHTSFilters } from '../interfaces/IHTSFilters';
import { ISortDirection } from '../interfaces/ISortDirection';
import { CustomObjectParam, removeEmpty } from '../utils/objects';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  BooleanParam,
  withDefault,
} from 'use-query-params';
import { useGlobalState } from '../contexts/GlobalStateProvider';

const columns = [
  HTSColumn.PlantCode,
  HTSColumn.PartNumber,
  HTSColumn.Description,
  HTSColumn.HTS,
  HTSColumn.CountryHTS,
  HTSColumn.DateClassified,
  HTSColumn.Rationale,
];

const HTS = () => {
  const { currentClient } = useGlobalState();

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sortBy: withDefault(StringParam, 'Date Classified'),
    sortDirection: withDefault(StringParam, 'desc'),
    filters: withDefault(CustomObjectParam, {}),
    showFilter: withDefault(BooleanParam, false),
  });

  const useDataResponse = useData({
    table: 'hts',
    page: query.page,
    perPage: 50,
    sortBy: query.sortBy,
    sortDirection: query.sortDirection as ISortDirection,
    client_code: currentClient || undefined,
    ...query.filters,
  });

  const { status, data: _data, error, isLoading, isFetching } = useDataResponse;

  const handleReportFilters = (filters: IHTSFilters) => {
    setQuery({
      ...query,
      page: 1,
      filters: removeEmpty(filters),
    });
  };

  return (
    <>
      <Heading
        title="HTS"
        reportShowFilterClick={() =>
          setQuery({ ...query, showFilter: !query.showFilter })
        }
      />
      <HTSDataTableFilter
        showFilter={query.showFilter}
        reportFilters={handleReportFilters}
      />
      <DataTable
        reportPage={(_page: number) => setQuery({ ...query, page: _page })}
        reportSort={(sortBy: keyof IHTS, sortDirection: ISortDirection) => {
          setQuery({ ...query, sortBy: sortBy as any, sortDirection });
        }}
        filters={{
          table: 'hts',
          page: query.page,
          perPage: 20,
          sortBy: query.sortBy,
          sortDirection: query.sortDirection,
          ...query.filters,
        }}
        status={status}
        data={_data}
        error={error}
        isLoading={isLoading || isFetching}
        columns={columns}
        page={query.page}
      />
    </>
  );
};

export default HTS;
