import axios from 'axios';
import { useQuery } from 'react-query';
import Http from '../services/http';

export function useClients() {
  return useQuery<string[]>(
    'clients',
    async () => {
      const { data } = await axios.get(`${process.env.REACT_APP_API}/clients`, {
        headers: {
          Authorization: `Bearer ${Http.accessToken}`,
        },
      });
      return data;
    },
    { keepPreviousData: true, refetchOnWindowFocus: false }
  );
}
