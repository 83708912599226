import DataTable from '../components/DataTable';
import Heading from '../components/Heading';
import { SupplierColumn } from '../enums/SupplierColumn';
import { useData } from '../hooks/useData';
import { IFTASuppliersFilters } from '../interfaces/IFTASuppliersFilters';
import { IHTSFilters } from '../interfaces/IHTSFilters';
import { ISortDirection } from '../interfaces/ISortDirection';
import { ISupplier } from '../interfaces/ISupplier';
import FTASuppliersDataTableFilter from '../components/data-table/FTASuppliersDataTableFilter';
import { CustomObjectParam, removeEmpty } from '../utils/objects';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
  BooleanParam,
} from 'use-query-params';
import { useGlobalState } from '../contexts/GlobalStateProvider';

const columns = [
  SupplierColumn.PlantCode,
  SupplierColumn.PartNumber,
  SupplierColumn.Description,
  SupplierColumn.SupplierName,
  SupplierColumn.Program,
  SupplierColumn.ProgramYear,
  SupplierColumn.CertificateNumber,
  SupplierColumn.Status,
];

const FTASuppliers = () => {
  const { currentClient } = useGlobalState();

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sortBy: withDefault(StringParam, 'Date Completed'),
    sortDirection: withDefault(StringParam, 'desc'),
    filters: withDefault(CustomObjectParam, {}),
    showFilter: withDefault(BooleanParam, false),
  });

  const {
    status,
    data: _data,
    error,
    isLoading,
    isFetching,
  } = useData({
    table: 'fta_supplier',
    page: query.page,
    perPage: 50,
    sortBy: query.sortBy,
    client_code: currentClient || undefined,
    sortDirection: query.sortDirection as ISortDirection,
    ...query.filters,
  });

  const handleReportFilters = (filters: IFTASuppliersFilters | IHTSFilters) => {
    setQuery({
      ...query,
      page: 1,
      filters: removeEmpty(filters),
    });
  };

  return (
    <>
      <Heading
        title="Supplier Responses"
        reportShowFilterClick={() =>
          setQuery({ ...query, showFilter: !query.showFilter })
        }
      />
      <FTASuppliersDataTableFilter
        showFilter={query.showFilter}
        reportFilters={handleReportFilters}
      />
      <DataTable
        reportPage={(_page: number) => setQuery({ ...query, page: _page })}
        reportSort={(
          sortBy: keyof ISupplier,
          sortDirection: ISortDirection
        ) => {
          setQuery({ ...query, sortBy: sortBy as any, sortDirection });
        }}
        status={status}
        data={_data}
        error={error}
        isLoading={isLoading || isFetching}
        columns={columns}
        page={query.page}
        filters={{
          table: 'fta_supplier',
          page: query.page,
          perPage: 20,
          sortBy: query.sortBy,
          sortDirection: query.sortDirection,
          ...query.filters,
        }}
      />
    </>
  );
};

export default FTASuppliers;
