import { useAuth0 } from '@auth0/auth0-react';
import Heading from '../components/Heading';
import InviteUsers from '../components/settings/InviteUsers';
import ManageUsers from '../components/settings/ManageUsers';
import { userIsAdmin } from '../utils/broker';

const Settings = () => {
  const { user } = useAuth0();

  const isAdmin = userIsAdmin(user);

  return isAdmin ? (
    <>
      <Heading title={'User Management'} showFilter={false} />
      <InviteUsers />
      <ManageUsers />
    </>
  ) : null;
};

export default Settings;
