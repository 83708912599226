import { BrokerReferralForm } from '../components/broker-referral/BrokerReferralForm';

const BrokerReferral = () => {
  return (
    <div className="w-full">
      <div className="max-w-5xl mx-auto">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-3">
          Broker Referral
        </h3>
        <p className="text-sm mb-6 border-b border-gray-300 pb-6">
          Brokers can use this form to create a referral.
        </p>
        <BrokerReferralForm />
      </div>
    </div>
  );
};

export default BrokerReferral;
