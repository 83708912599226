import axios from 'axios';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Heading from '../components/Heading';
import ChangeNickname from '../components/profile/ChangeNickname';
import Http from '../services/http';

const Profile = () => {
  const handleClick = async () => {
    const response = await axios.get<any, { data: { url: string } }>(
      `${process.env.REACT_APP_API}/change-password`,
      {
        headers: {
          Authorization: `Bearer ${Http.accessToken}`,
        },
        params: {
          redirectUrl: window.location.href,
        },
      }
    );

    window.open(response?.data?.url, '_self');
  };
  return (
    <>
      <Heading title={'Profile'} showFilter={false} />
      <ChangeNickname />
      <PrimaryButton
        onClick={() => handleClick()}
        classname="mt-6"
        color="green"
      >
        Change Password
      </PrimaryButton>
    </>
  );
};

export default Profile;
