import classNames from 'classnames';
import React from 'react';
import { ButtonColor } from '../../enums/ButtonColor';

interface Props {
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  type?: 'submit' | 'button';
  classname?: string;
  color?: keyof typeof ButtonColor;
}

const GhostButton: React.FC<Props> = (props) => {
  const { onClick, disabled, type, classname, color, loading, children } =
    props;

  let mainColor = 'text-indigo-600 hover:bg-indigo-50 focus:ring-indigo-600';
  let disabledColor = 'text-indigo-300 hover:bg-transparent';

  switch (color) {
    case 'green': {
      mainColor = 'text-green-600 hover:bg-green-50 focus:ring-green-600';
      disabledColor = 'text-green-300 hover:bg-transparent';
      break;
    }
    case 'red': {
      mainColor = 'text-red-600 hover:bg-red-50 focus:ring-red-600';
      disabledColor = 'text-red-300 hover:bg-transparent';
      break;
    }
    case 'blue':
      break;
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      type={type}
      className={classNames(
        'focus:outline-none text-sm font-medium inline-flex items-center rounded-md px-4 py-2 focus:ring-2 focus:ring-offset-2',
        {
          [mainColor]: !disabled,
          [disabledColor]: disabled,
        },
        classname
      )}
    >
      {loading ? 'Loading' : children}
    </button>
  );
};

export default GhostButton;
