import classNames from 'classnames';
import { useExportMutation } from '../hooks/useExportMutation';
import { IDataFiltersDTO } from '../interfaces/IDataFiltersDTO';
import ExportButton from './buttons/ExportButton';
import { useNotifications } from './notifications/useNotifications';

interface PaginationDetails {
  perPage: number;
  currentPage: number;
  from: number;
  to: number;
  total: number;
  lastPage: number;
}

interface IPagination {
  pagination: PaginationDetails;
  onNext: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onPrevious: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onExport?: () => void;
  className?: string;
  filters?: IDataFiltersDTO;
  hideExport?: boolean;
}

interface IExportDataResponse {
  error?: boolean;
  url?: string;
  message?: string;
}

export const Pagination: React.FC<IPagination> = ({
  pagination,
  onNext,
  onPrevious,
  className,
  filters,
  hideExport,
}) => {
  const { from, to, total, currentPage, lastPage } = pagination || {};

  const { pushNotification } = useNotifications();

  const nextDisabled = currentPage >= lastPage;
  const prevDisabled = currentPage === 1;

  const { mutate, isLoading } = useExportMutation<IExportDataResponse>({
    onSuccess: (data: IExportDataResponse) => {
      if (data?.url) {
        window.open(data?.url, '_blank');
      } else if (!!data?.error && data?.message) {
        console.error(data?.message);

        pushNotification({
          heading: 'Error',
          type: 'error',
          content: data?.message,
        });
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const handleButtonClick = (type: 'csv' | 'xlsx') => {
    return filters && mutate({ ...filters, format: type });
  };

  return (
    <nav
      className={classNames(
        'bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 overflow-visible',
        className
      )}
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{from}</span> to{' '}
          <span className="font-medium">{to}</span> of{' '}
          <span className="font-medium">{total}</span> results
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        {!hideExport && (
          <ExportButton onClick={handleButtonClick} isLoading={isLoading} />
        )}
        <a
          href="#"
          onClick={onPrevious}
          className={classNames(
            'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
            {
              'pointer-events-none text-gray-300 border-gray-200': prevDisabled,
            }
          )}
        >
          Previous
        </a>
        <a
          href="#"
          onClick={onNext}
          className={classNames(
            'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50',
            {
              'pointer-events-none text-gray-300 border-gray-200': nextDisabled,
            }
          )}
        >
          Next
        </a>
      </div>
    </nav>
  );
};
