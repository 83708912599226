import { Field } from 'formik';
import React from 'react';

interface IDatePickerProps {
  name: string;
  title: string;
}

const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const { name, title } = props;

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <Field
        type="date"
        name={name}
        id={name}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      />
    </>
  );
};

export default DatePicker;
