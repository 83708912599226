import { UploadIcon } from '@heroicons/react/outline';
import { FC, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FileBadge } from './FileBadge';
import { useFormikContext } from 'formik';

interface Props {
  name: string;
  label: string;
}

const UploadFile: FC<Props> = ({ name, label }) => {
  const [files, setFiles] = useState<File[]>([]);

  const { setFieldValue } = useFormikContext();

  const onDrop = (acceptedFiles: File[]) => {
    setFiles((prev) => [...prev, ...acceptedFiles]);
  };

  useEffect(() => {
    setFieldValue(name, files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
  });

  const onDelete = (index: number) => {
    setFiles((prev) => prev.filter((file, i) => i !== index));
  };

  return (
    <div className="col-span-full">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900 mb-2"
      >
        {label}
      </label>
      <div className="grid grid-cols-2 gap-6">
        <div className={files.length > 0 ? 'col-span-1' : 'col-span-2'}>
          <div className="flex items-center justify-center w-full">
            <div
              {...getRootProps()}
              className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
            >
              <input {...getInputProps()} />
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <UploadIcon className="w-8 h-8 mb-4 text-gray-500" />
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">
                    {isDragActive
                      ? 'Drop the files here ...'
                      : 'Click to upload'}
                  </span>{' '}
                  or drag and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
            </div>
          </div>
        </div>
        {files.length > 0 && (
          <div className="col-auto flex flex-col gap-2">
            {files.map((x, i) => (
              <FileBadge key={`file-badge-${i}`} onDelete={() => onDelete(i)}>
                {x.name}
              </FileBadge>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadFile;
