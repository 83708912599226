import classNames from 'classnames';
import { FC } from 'react';

interface Props {
  classes?: string;
}

const Dot = () => (
  <div className="w-3 h-3 bg-gray-50 rounded-full animate-bounce"></div>
);

const SubmitButtonLoader: FC<Props> = ({ classes }) => {
  return (
    <div
      className={classNames(
        'loader bg-white p-1 rounded-full flex gap-3 w-full h-auto bg-transparent',
        classes
      )}
    >
      <Dot />
      <Dot />
      <Dot />
    </div>
  );
};

export default SubmitButtonLoader;
