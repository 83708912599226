import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import Http from '../../services/http';
import PrimaryButton from '../buttons/PrimaryButton';
import { useNotifications } from '../notifications/useNotifications';

interface IChangeNicknameMutation {
  nickname: string;
}

const ChangeNickname = () => {
  const { pushNotification } = useNotifications();
  const { user } = useAuth0();

  const mutation = useMutation<unknown, unknown, IChangeNicknameMutation>(
    (nickname) => {
      return axios.patch(
        `${process.env.REACT_APP_API}/change-nickname`,
        nickname,
        {
          headers: {
            Authorization: `Bearer ${Http.accessToken}`,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        if (user) user.nickname = (data as any)?.data?.nickname;

        pushNotification({
          heading: 'Username Changed',
          content: `Your username was changed to ${
            (data as any)?.data?.nickname
          }`,
        });
      },
    }
  );

  return (
    <Formik
      initialValues={{ nickname: '' }}
      onSubmit={(values, { resetForm }) => {
        mutation.mutate({
          nickname: values?.nickname,
        });

        resetForm();
      }}
    >
      {({ isSubmitting, isValid }) => {
        return (
          <Form>
            <div
              className={classNames('mt-5 md:mt-0 md:col-span-2', {
                'opacity-30': isSubmitting,
              })}
            >
              <fieldset disabled={isSubmitting}>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <h3 className="font-medium mb-4">Change Username</h3>

                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-1">
                        <label
                          htmlFor="nickname"
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Username
                        </label>
                        <Field
                          type="text"
                          name="nickname"
                          id="nickname"
                          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                          placeholder={user?.nickname}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <PrimaryButton
                      disabled={!isValid}
                      type="submit"
                      color="green"
                    >
                      Change Username
                    </PrimaryButton>
                  </div>
                </div>
              </fieldset>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeNickname;
