import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { useAuth0 } from '@auth0/auth0-react';
import { useGlobalState } from '../contexts/GlobalStateProvider';

const ClientSelect = () => {
  const { currentClient, setCurrentClient } = useGlobalState();

  const auth0 = useAuth0();

  const auth0Client =
    auth0?.user?.['http://focus-solutions.net/user_metadata']?.client_code;

  const clients = Array.isArray(auth0Client) ? auth0Client : [auth0Client];

  const shouldDisplay = clients.length > 1 && currentClient;

  return shouldDisplay ? (
    <div className="mb-2 mx-2">
      <Listbox value={currentClient} onChange={setCurrentClient}>
        <div className="relative mt-2">
          <Listbox.Button className="relative w-full cursor-default rounded-md bg-gray-200 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[#1C2954] sm:text-sm sm:leading-6">
            <span className="block truncate">{currentClient}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </span>
          </Listbox.Button>

          <Listbox.Options className="absolute bottom-full z-10 mb-1 max-h-60 w-full overflow-auto rounded-md bg-gray-200 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {clients.map((client: string) => (
              <Listbox.Option
                key={client}
                value={client}
                className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-focus-green hover:text-white"
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-semibold' : 'font-normal'
                      }`}
                    >
                      {client}
                    </span>
                    {selected && (
                      <span
                        className={`absolute inset-y-0 right-0 flex items-center pr-4`}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  ) : null;
};

export default ClientSelect;
