import { Field, useFormikContext } from 'formik';
import { FC } from 'react';

interface Props {
  name: string;
  label: string;
  description?: string;
  classes?: string;
}

const TextAreaField: FC<Props> = ({ name, label, description, classes }) => {
  const { errors, touched } = useFormikContext<{ [key: string]: string }>();

  return (
    <div className={classes}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <Field
          id={name}
          name={name}
          component="textarea"
          rows="3"
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        {errors[name] && touched[name] ? (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errors[name]}
          </p>
        ) : null}
      </div>
      {description && (
        <p className="mt-3 text-sm leading-6 text-gray-600">{description}</p>
      )}
    </div>
  );
};

export default TextAreaField;
