import { Field } from 'formik';
import React from 'react';

interface ITextFieldProps {
  name: string;
  title: string;
  placeholder?: string;
}

const TextField: React.FC<ITextFieldProps> = (props) => {
  const { name, title, placeholder = '' } = props;

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <Field
        type="text"
        name={name}
        placeholder={placeholder}
        id={name}
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
      />
    </>
  );
};

export default TextField;
