import { Field } from 'formik';
import React from 'react';

interface ISelectMenuProps {
  name: string;
  title: string;
  options: string[];
  defaultValue?: string;
}

const SelectMenu: React.FC<ISelectMenuProps> = (props) => {
  const { name, title, options, defaultValue } = props;

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {title}
      </label>
      <Field
        as="select"
        id={name}
        name={name}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        defaultValue={defaultValue || options[0]}
      >
        {options.map((option) => (
          <option value={option}>{option}</option>
        ))}
      </Field>
    </>
  );
};

export default SelectMenu;
