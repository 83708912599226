import DataTable from '../components/DataTable';
import Heading from '../components/Heading';
import { useData } from '../hooks/useData';
import { ISortDirection } from '../interfaces/ISortDirection';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import { IBroker } from '../interfaces/IBroker';
import { ReferralColumn } from '../enums/ReferralsColumn';
import { useAuth0 } from '@auth0/auth0-react';
import { userIsAdmin, userIsBroker } from '../utils/broker';
import { PencilAltIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { SectionPath } from '../enums/admin/Sections';
import { useGlobalState } from '../contexts/GlobalStateProvider';

const columns = [
  ReferralColumn.date_requested,
  ReferralColumn.date_due,
  ReferralColumn.plant_code,
  ReferralColumn.release_date,
  ReferralColumn.entry_number,
  ReferralColumn.created_at,
  ReferralColumn.updated_at,
];

const Referrals = () => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sortBy: withDefault(StringParam, 'created_at'),
    sortDirection: withDefault(StringParam, 'desc'),
  });

  const { currentClient } = useGlobalState();

  const { user } = useAuth0();

  const isBroker = userIsBroker(user);
  const isAdmin = userIsAdmin(user);

  const {
    status,
    data: _data,
    error,
    isLoading,
    isFetching,
  } = useData({
    table: 'broker_referrals',
    page: query.page,
    perPage: 50,
    sortBy: query.sortBy,
    sortDirection: query.sortDirection as ISortDirection,
    client_code: currentClient || '',
  });

  return (
    <>
      <Heading title="Referrals" showFilter={false}>
        {!!(isBroker || isAdmin) && (
          <Link
            to={SectionPath.broker_referral}
            className="group flex items-center px-2 py-2 text-sm font-medium rounded-md bg-focus-green text-white hover:bg-focus-green/80 mx-2 my-3"
          >
            <PencilAltIcon className="h-6 w-6 mr-3" />
            Create Referral
          </Link>
        )}
      </Heading>
      <DataTable
        reportPage={(_page: number) => setQuery({ ...query, page: _page })}
        reportSort={(sortBy: keyof IBroker, sortDirection: ISortDirection) => {
          setQuery({ ...query, sortBy: sortBy as any, sortDirection });
        }}
        status={status}
        data={_data}
        error={error}
        isLoading={isLoading || isFetching}
        columns={columns}
        page={query.page}
        hideExport
        isInternalTable
      />
    </>
  );
};

export default Referrals;
