export enum SectionPath {
  dashboard = '/',
  hts = '/hts',
  fta_supplier = '/fta-supplier',
  fta_issued = '/fta-issued',
  extracts = '/extracts',
  settings = '/settings',
  profile = '/profile',
  broker = '/broker',
  broker_referral = '/broker-referral',
  referrals = '/referrals',
}
