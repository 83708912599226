import { Popover } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { useFormikContext } from 'formik';
import * as XLSX from 'xlsx';

const PartNumberFileUpload = () => {
  const { setFieldValue } = useFormikContext();

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const firstColumnData = (jsonData as any)
          .slice(1)
          .map((row: any[]) => row[0])
          .filter(
            (value: any) =>
              value !== null && value !== undefined && value !== ''
          );

        setFieldValue('part_number', firstColumnData.join(', '));
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div className="inline-flex justify-center gap-3 items-center overflow-visible">
      <label
        htmlFor="part_number_file_input"
        className="py-2 cursor-pointer px-4 border border-gray-500 shadow-sm text-sm font-medium rounded-md text-gray-500 bg-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
      >
        <input
          type="file"
          id="part_number_file_input"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
        />
        Upload Part Numbers File
      </label>
      <Popover className="relative leading-none">
        <Popover.Button>
          <InformationCircleIcon className="text-gray-400 w-6 h-6 align-middle" />
        </Popover.Button>
        <Popover.Panel className="absolute z-50 w-80 left-1/2 bottom-0 mb-2 ml-6 bg-white rounded-md shadow-sm p-2 border border-gray-100">
          <p className="text-sm text-gray-700 w-full text-left">
            Upload an Excel (.xlsx) file with a single column of part numbers.
            The first row should be the header.
          </p>
        </Popover.Panel>
      </Popover>
    </div>
  );
};

export default PartNumberFileUpload;
