import { MenuIcon } from '@heroicons/react/outline';
import { Route, Routes } from 'react-router-dom';
import { SectionPath } from '../enums/admin/Sections';
import Dashboard from '../pages/Dashboard';
import Extracts from '../pages/Extracts';
import FTASuppliers from '../pages/FTASuppliers';
import FTAIssued from '../pages/FTAIssued';
import HTS from '../pages/HTS';
import SingleHTSItem from '../pages/single-items/SingleHTSItem';
import SingleIssuedItem from '../pages/single-items/SingleIssuedItem';
import SingleSupplierItem from '../pages/single-items/SingleSupplierItem';
import Settings from '../pages/Settings';
import Callback from '../pages/Callback';
import Profile from '../pages/Profile';
import Broker from '../pages/Broker';
import BrokerReferral from '../pages/BrokerReferral';
import Referrals from '../pages/Referrals';
import SingleReferralItem from '../pages/single-items/SingleReferralItem';
import { useAuth0 } from '@auth0/auth0-react';
import SingleBrokerItem from '../pages/single-items/SingleBrokerItem';
import { userIsAdmin, userIsBroker } from '../utils/broker';
import PageWrapper from './PageWrapper';

interface IContent {
  reportSidebarOpen: (e: boolean) => void;
}

const Content = (props: IContent) => {
  const { reportSidebarOpen } = props;
  const { user } = useAuth0();

  const isBroker = userIsBroker(user);
  const isAdmin = userIsAdmin(user);

  return (
    <div className="flex flex-col w-0 flex-1 overflow-hidden h-full">
      <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button
          type="button"
          className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          onClick={() => reportSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <PageWrapper>
          <Routes>
            <Route path={SectionPath.dashboard} element={<Dashboard />} />
            <Route path={SectionPath.hts} element={<HTS />} />
            <Route path={SectionPath.fta_supplier} element={<FTASuppliers />} />
            {!!(isBroker || isAdmin) && (
              <>
                <Route path={SectionPath.broker} element={<Broker />} />
                <Route path={SectionPath.referrals} element={<Referrals />} />
                <Route
                  path={`${SectionPath.broker}/:id`}
                  element={<SingleBrokerItem />}
                />
              </>
            )}
            <Route
              path={SectionPath.broker_referral}
              element={<BrokerReferral />}
            />
            <Route path={SectionPath.fta_issued} element={<FTAIssued />} />
            <Route path={SectionPath.extracts} element={<Extracts />} />
            <Route
              path={`${SectionPath.hts}/:id`}
              element={<SingleHTSItem />}
            />
            <Route
              path={`${SectionPath.fta_issued}/:id`}
              element={<SingleIssuedItem />}
            />
            <Route
              path={`${SectionPath.fta_supplier}/:id`}
              element={<SingleSupplierItem />}
            />
            <Route
              path={`${SectionPath.referrals}/:uuid`}
              element={<SingleReferralItem />}
            />
            <Route path={SectionPath.settings} element={<Settings />} />
            <Route path={SectionPath.profile} element={<Profile />} />
            <Route path="/callback" element={<Callback />} />
          </Routes>
        </PageWrapper>
      </main>
    </div>
  );
};

export default Content;
