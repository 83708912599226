import { useAuth0 } from '@auth0/auth0-react';
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';

interface GlobalState {
  currentClient: string | null;
  setCurrentClient: (client: string | null) => void;
}

const GlobalStateContext = createContext<GlobalState | undefined>(undefined);

interface Props {
  children: ReactNode;
}

export const GlobalStateProvider: React.FC<Props> = ({ children }) => {
  const [currentClient, setCurrentClient] = useState<string | null>(null);

  const auth0 = useAuth0();

  const auth0clients =
    auth0?.user?.['http://focus-solutions.net/user_metadata']?.client_code;

  const clients = useMemo(
    () => (Array.isArray(auth0clients) ? auth0clients : [auth0clients]),
    [auth0clients]
  );

  useEffect(() => {
    if (!currentClient && clients?.length) {
      setCurrentClient(clients[0]);
    }
  }, [clients, currentClient]);

  const value = {
    currentClient,
    setCurrentClient,
  };

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};
