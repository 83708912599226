import { Link } from 'react-router-dom';

interface ISummaryFooter {
  tableName?: string;
  link?: string;
}

const SummaryFooter: React.FC<ISummaryFooter> = (props) => {
  const { tableName = 'Table', link = '' } = props;

  return (
    <div
      className="bg-white px-4 py-3 flex items-center justify-end border-t border-gray-200 sm:px-6"
      aria-label="Summary"
    >
      <Link
        type="button"
        to={link}
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
        style={{
          background: '#4BB15F',
        }}
      >
        Go to {tableName}
      </Link>
    </div>
  );
};

export default SummaryFooter;
