import { useState } from 'react';
import {
  GlobeIcon,
  HomeIcon,
  LibraryIcon,
  UserGroupIcon,
  SparklesIcon,
  FolderOpenIcon,
} from '@heroicons/react/outline';
import Sidebar from '../components/Sidebar';
import Content from '../components/Content';
import { useLocation } from 'react-router-dom';
import { SectionPath } from '../enums/admin/Sections';
import MobileSidebar from '../components/MobileSidebar';
import { useNotifications } from '../components/notifications/useNotifications';
import { Notification } from '../components/notifications/Notification';
import { useAuth0 } from '@auth0/auth0-react';
import { userIsAdmin, userIsBroker } from '../utils/broker';

const Container = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  const { notifications } = useNotifications();
  const { user } = useAuth0();

  const isBroker = userIsBroker(user);
  const isAdmin = userIsAdmin(user);

  const showBroker = isAdmin || isBroker;
  const showUser = isAdmin || !isBroker;

  const current = (id: string) => location.pathname === id;

  const navigation = [
    {
      name: 'Dashboard',
      href: SectionPath.dashboard,
      icon: HomeIcon,
      current: current(SectionPath.dashboard),
    },
    showUser
      ? {
          name: 'HTS',
          href: SectionPath.hts,
          icon: LibraryIcon,
          current: current(SectionPath.hts),
        }
      : undefined,
    showUser
      ? {
          name: 'Supplier Responses',
          href: SectionPath.fta_supplier,
          icon: UserGroupIcon,
          current: current(SectionPath.fta_supplier),
        }
      : undefined,
    showUser
      ? {
          name: 'FTA Issued',
          href: SectionPath.fta_issued,
          icon: GlobeIcon,
          current: current(SectionPath.fta_issued),
        }
      : undefined,
    showBroker
      ? {
          name: 'Broker',
          href: SectionPath.broker,
          icon: SparklesIcon,
          current: current(SectionPath.broker),
        }
      : undefined,
    showBroker
      ? {
          name: 'Referrals',
          href: SectionPath.referrals,
          icon: FolderOpenIcon,
          current: current(SectionPath.referrals),
        }
      : undefined,
  ].filter(Boolean);

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        reportSidebarOpen={setSidebarOpen}
        navigation={navigation}
      />
      <Sidebar navigation={navigation} />
      <Content reportSidebarOpen={setSidebarOpen} />
      {notifications.map((notification, index) => (
        <Notification
          notification={notification}
          key={`notification-${index}`}
        />
      ))}
    </div>
  );
};

export default Container;
