import axios from 'axios';
import classNames from 'classnames';
import { useMutation, useQueryClient } from 'react-query';
import { useUsers } from '../../hooks/useUsers';
import { IAuth0User } from '../../interfaces/IAuth0User';
import Http from '../../services/http';
import { useNotifications } from '../notifications/useNotifications';
import SpinningLoader from '../Loader';

const ManageUsers = () => {
  const { data, isLoading, isRefetching, isFetching } = useUsers();
  const queryClient = useQueryClient();
  const { pushNotification } = useNotifications();

  const mutation = useMutation<unknown, unknown, { id: string }>(
    ({ id }) => {
      return axios.delete(`${process.env.REACT_APP_API}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${Http.accessToken}`,
        },
      });
    },
    {
      onSuccess: () => {
        pushNotification({
          heading: 'User Deleted',
          content: `The user was deleted.`,
        });
        queryClient.invalidateQueries('users');
      },
    }
  );

  return isLoading || isRefetching || isFetching ? (
    <div className="w-full h-full flex justify-center items-center">
      <SpinningLoader />
    </div>
  ) : (
    <div
      className={classNames(
        '-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg',
        {
          'opacity-30': isLoading,
          'animate-pulse': isLoading,
        }
      )}
    >
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Name
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Client Code
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              User Type
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Delete</span>
            </th>
          </tr>
        </thead>
        <tbody className={'divide-y divide-gray-200 bg-white'}>
          {data
            ?.filter(
              (user: IAuth0User) =>
                !user?.roles?.find((x) => x.name === 'Administrator')
            )
            .map((user: IAuth0User) => {
              const clientCodes = Array.isArray(
                user?.user_metadata?.client_code
              )
                ? user?.user_metadata?.client_code.join(', ')
                : user?.user_metadata?.client_code;

              const userType = user?.roles?.find((x) => x.name === 'Broker')
                ? 'Broker'
                : 'Standard';

              return (
                <tr key={user?.email}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {user?.nickname || user?.name}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Title</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {user?.nickname || user?.name}
                      </dd>
                      <dt className="sr-only sm:hidden">Email</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {clientCodes}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {user?.email}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {clientCodes}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500">
                    {userType}
                  </td>
                  <td className="px-3 py-4 text-right text-sm font-medium sm:pr-6">
                    <button
                      type="button"
                      onClick={() => mutation.mutate({ id: user?.user_id })}
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ManageUsers;
