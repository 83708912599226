import classNames from 'classnames';
import { FC } from 'react';
import SubmitButtonLoader from './SubmitButtonLoader';

interface Props {
  isValid?: boolean;
  isLoading?: boolean;
}

const SubmitButton: FC<Props> = ({ isValid, isLoading }) => {
  return (
    <button
      type="submit"
      disabled={!isValid || isLoading}
      className={classNames(
        'inline-flex w-auto justify-center py-2 px-12 focus:outline-none focus:ring-2 focus:ring-offset-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white',
        {
          'bg-indigo-600 hover:bg-indigo-700  focus:ring-indigo-500': isValid,
          'bg-indigo-300 hover:bg-indigo-300  focus:ring-indigo-300': !isValid,
        }
      )}
    >
      {isLoading ? <SubmitButtonLoader /> : 'Submit'}
    </button>
  );
};

export default SubmitButton;
