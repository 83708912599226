import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { useSingleData } from '../../hooks/useSingleData';
import { formatFields } from '../../utils/formatting';

const SingleSupplierItem = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useSingleData('fta_supplier', id || '');

  const hiddenItems = [
    'id',
    'created_at',
    'updated_at',
    'Start Date',
    'End Date',
    'Client HTS',
  ];

  const sortingArr = [
    'Client Code',
    'Database Code',
    'Part Number',
    'Plant Code',
    'Certificate Number',
    'Signature Date',
    'Blanket Start',
    'Blanket End',
    'EndUse',
    'Description',
    'Supplier ID',
    'Supplier Name',
    'Program',
    'Status',
    'Client HTS',
    'Date Completed',
    'Supplier Part Number',
    'Program Year',
    'Response1',
    'Response2',
    'Response3',
    'Response4',
    'Response5',
    'Response6',
    'Response7',
    'Response8',
    'Response9',
    'Response10',
  ];

  return data && !isLoading && !isError ? (
    <>
      <button
        className="py-5 flex justify-center items-center opacity-80 hover:opacity-100 self-start"
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon className="h-5 w-5 mr-3" />
        <h3 className="text-lg leading-6 font-medium text-gray-900">Back</h3>
      </button>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Supplier Response
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {Object.keys(data)
              .filter((item) => !hiddenItems.includes(item))
              .sort(
                (a: string, b: string) =>
                  sortingArr.indexOf(a) - sortingArr.indexOf(b)
              )
              .map((item: string, index: number) => {
                const displayValue = formatFields(item, data[item]);

                return (
                  <div
                    className={`sm:col-span-1 single-item-key-${index}`}
                    key={`single-item-key-${index}`}
                  >
                    <dt className="text-sm font-medium text-gray-500">
                      {item}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {displayValue}
                    </dd>
                  </div>
                );
              })}
          </dl>
        </div>
      </div>
    </>
  ) : null;
};

export default SingleSupplierItem;
