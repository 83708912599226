import { IDataItem } from '../interfaces/IDataItem';

export const formatFields = (
  fieldName: keyof IDataItem,
  fieldValue: string
) => {
  if (
    fieldName === 'Date Classified' ||
    fieldName === 'Blanket Start' ||
    fieldName === 'Blanket End' ||
    fieldName === 'Signature Date' ||
    fieldName === 'Date Completed' ||
    fieldName === 'Date Due' ||
    fieldName === 'Date Requested' ||
    fieldName === 'Release Date' ||
    fieldName === 'Effective Date' ||
    fieldName === 'Expiry Date' ||
    fieldName === 'Date Transmitted' ||
    fieldName === 'release_date' ||
    fieldName === 'date_requested' ||
    fieldName === 'date_due'
  ) {
    return fieldValue
      ? new Date(fieldValue).toLocaleDateString('en-US', { timeZone: 'UTC' })
      : fieldValue;
  }

  return fieldValue;
};

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
