export enum HTSColumn {
  'ClientCode' = 'Client Code',
  'DatabaseCode' = 'DatabaseCode',
  'PlantCode' = 'Plant Code',
  'PartNumber' = 'Part Number',
  'EndUse' = 'EndUse',
  'Description' = 'Description',
  'CountryHTS' = 'Country HTS',
  'HTS' = 'HTS',
  'DateClassified' = 'Date Classified',
  'ConfidenceLevel' = 'Confidence Level',
  'Rationale' = 'Rationale',
  'StartDate' = 'Start Date',
  'EndDate' = 'End Date',
  'GRI' = 'GRI',
  'Rulings' = 'Rulings',
  'ECCN' = 'ECCN',
  'ExportLicense' = 'Export License',
  'EXPLIC' = 'EXPLIC',
  'FCC' = 'FCC',
  'FDA' = 'FDA',
  'ADD' = 'ADD',
  'CVD' = 'CVD',
  'EPA' = 'EPA',
  'NHTSA' = 'NHTSA',
  'id' = 'id',
}
