export const ReferralRequestTypes: Record<string, string> = {
  'ADD/CVD': 'ADD/CVD',
  COO: 'Country of Origin',
  CUR: 'Currency',
  DEST: 'Destination Code',
  'HTS-CA': 'Classification CA',
  'HTS-EU': 'Classification EU',
  'HTS-MX': 'Classification MX',
  'HTS-US': 'Classification US',
  INCO: 'Incoterm Use',
  IOR: 'Importer of Record',
  MA: 'US Goods Returned',
  NPN: 'No Part Number',
  OTH: 'Other',
  PGA: 'Partner Government Agencies',
  SUPP: 'Supplier Code',
  USMCA: 'USMCA',
  301: '301',
  KOREA: 'US-KOREA',
  CHILE: 'US-CHILE',
  AUSTRALIA: 'US-AUSTRALIA',
  COLOMBIA: 'US-COLOMBIA',
  ISRAEL: 'US-ISRAEL',
  PANAMA: 'US-PANAMA',
  PERU: 'US-PERU',
  SINGAPORE: 'US-SINGAPORE',
  CAFTA: 'US-CAFTA',
  VALUE: 'VALUE',
  OMAN: 'US-OMAN',
  OSP: 'OSP',
};
