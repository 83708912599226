import { encodeObject, decodeObject } from 'use-query-params';

export const removeEmpty = (obj: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != null && v !== '')
  );

const keyValueSeparator = '___'; // default is "-"
const entrySeparator = '...'; // default is "_"

export const CustomObjectParam = {
  encode: (obj: any) => encodeObject(obj, keyValueSeparator, entrySeparator),
  decode: (str: any) => decodeObject(str, keyValueSeparator, entrySeparator),
};
