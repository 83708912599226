import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { useSingleData } from '../../hooks/useSingleData';
import { formatFields } from '../../utils/formatting';
import { ReferralColumn } from '../../enums/ReferralsColumn';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { PartsSummary } from '../../components/broker-referral/PartsSummary';

const FileLinkButton = ({ link }: { link: string }) => {
  const url = new URL(link);

  const decodedPathname = decodeURIComponent(
    url.pathname.split('/').pop() || ''
  );

  return (
    <PrimaryButton onClick={() => window.open(link, '_blank')}>
      {decodedPathname}
    </PrimaryButton>
  );
};

const SingleReferralItem = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const navigate = useNavigate();

  const { data, isLoading, isError } = useSingleData(
    'broker_referrals',
    uuid || ''
  );

  const hiddenItems = [
    'id',
    'created_at',
    'updated_at',
    'uuid',
    'user_id',
    'email',
    'contact_name',
    'parts',
    'part_number',
    'part_description',
    'request_type',
  ];

  return data && !isLoading && !isError ? (
    <>
      <button
        className="py-5 flex justify-center items-center opacity-80 hover:opacity-100 self-start"
        onClick={() => navigate('/referrals')}
      >
        <ChevronLeftIcon className="h-5 w-5 mr-3" />
        <h3 className="text-lg leading-6 font-medium text-gray-900">Back</h3>
      </button>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Referral Details
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {Object.keys(data)
              .filter((item) => !hiddenItems.includes(item))
              .map((item: string, index: number) => {
                const displayValue = formatFields(item, data[item]);

                const label =
                  ReferralColumn[item as keyof typeof ReferralColumn];

                return (
                  <div
                    className={`sm:col-span-1 single-item-key-${index}`}
                    key={`single-item-key-${index}`}
                  >
                    <dt className="text-sm font-medium text-gray-500">
                      {label}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {label === 'File Link' && Array.isArray(data[item]) ? (
                        <div className="flex flex-col gap-2 items-start">
                          {data[item].map((x: any) => (
                            <FileLinkButton link={x} />
                          ))}
                        </div>
                      ) : (
                        displayValue
                      )}
                    </dd>
                  </div>
                );
              })}
          </dl>
          <PartsSummary parts={data.parts} />
        </div>
      </div>
    </>
  ) : null;
};

export default SingleReferralItem;
