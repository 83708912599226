import { FC, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { DocumentIcon, XIcon } from '@heroicons/react/outline';

interface Props {
  onDelete: () => void;
}

export const FileBadge: FC<Props> = ({ onDelete, children }) => {
  return (
    <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
      <Transition
        appear
        show={true}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center">
              <p className="flex-1 text-sm font-medium text-gray-900 flex gap-2">
                <DocumentIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                {children}
              </p>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={onDelete}
                >
                  <span className="sr-only">Delete</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};
