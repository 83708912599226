import { Transition } from '@headlessui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { IFTASuppliersFilters } from '../../interfaces/IFTASuppliersFilters';
import { IHTSFilters } from '../../interfaces/IHTSFilters';
import DatePicker from '../forms/DatePicker';
import TextField from '../forms/TextField';
import {
  useQueryParams,
  NumberParam,
  StringParam,
  withDefault,
} from 'use-query-params';
import { CustomObjectParam } from '../../utils/objects';
import PartNumberFileUpload from './PartNumberFileUpload';

interface IDataTableFilterProps {
  showFilter: boolean;
  reportFilters?: (filters: IHTSFilters | IFTASuppliersFilters) => void;
}

const HTSDataTableFilter: React.FC<IDataTableFilterProps> = ({
  showFilter,
  reportFilters = () => ({}),
}) => {
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    sortBy: withDefault(StringParam, 'Date Classified'),
    sortDirection: withDefault(StringParam, 'desc'),
    filters: withDefault(CustomObjectParam, {} as any),
  });

  return (
    <Transition
      show={showFilter}
      enter="transition ease duration-200 transform"
      enterFrom="opacity-0 -translate-y-10"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease duration-300 transform"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 -translate-y-10"
    >
      <div className="mt-10 sm:mt-0 mb-10 sm:mb-4 transition-opacity">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <Formik
            initialValues={{
              country_hts: query.filters?.country_hts || '',
              date_classified_end: query.filters?.date_classified_end || '',
              date_classified_start: query.filters?.date_classified_start || '',
              description: query.filters?.description || '',
              hts_number: query.filters?.hts_number || '',
              part_number: query.filters?.part_number || '',
              hts_number_empty: query.filters?.hts_number_empty || false,
            }}
            onSubmit={(values) => {
              reportFilters(values);
            }}
          >
            {({ resetForm }) => (
              <Form>
                <div className="shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <TextField
                          name="part_number"
                          title="Part Number (or comma separated list)"
                          placeholder="Contains"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <TextField
                          name="hts_number"
                          title="HTS Number"
                          placeholder="Contains"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <TextField
                          name="description"
                          title="Description"
                          placeholder="Contains"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-white sm:px-6 sm:py-1">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <DatePicker
                          name="date_classified_start"
                          title="Date Classified Start"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <DatePicker
                          name="date_classified_end"
                          title="Date Classified End"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <TextField
                          name="country_hts"
                          title="Country HTS"
                          placeholder="Contains"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-white text-right sm:px-6 flex gap-2 items-center justify-between">
                    <PartNumberFileUpload />
                    <div className="flex gap-2">
                      <button
                        type="button"
                        onClick={() => {
                          resetForm();
                          setQuery({ ...query, filters: {} });
                          reportFilters({});
                        }}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        style={{
                          background: '#4BB15F',
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Transition>
  );
};

export default HTSDataTableFilter;
