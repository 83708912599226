import axios from 'axios';
import { useMutation } from 'react-query';
import { IDataItem } from '../interfaces/IDataItem';
import Http from '../services/http';

export const useExportMutation = <T>({
  onSuccess,
  onError,
  onSettled,
}: {
  onSuccess: (data: T) => void;
  onError: (error: unknown) => void;
  onSettled?: (data: any) => void;
}) =>
  useMutation<T, unknown, Partial<IDataItem>>(
    async ({
      table,
      page = 1,
      perPage = 10,
      sortBy,
      sortDirection,
      confidence_level,
      date_classified_end,
      date_classified_start,
      description,
      hts_number,
      part_number,
      cert_number,
      blanket_end,
      blanket_start,
      supplier_name,
      plant_code,
      fta,
      format,
    }) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API}/download-data`,
          {
            params: {
              page,
              perPage,
              sortBy,
              sortDirection,
              table,
              confidence_level,
              date_classified_end,
              date_classified_start,
              description,
              hts_number,
              part_number,
              cert_number,
              blanket_end,
              blanket_start,
              supplier_name,
              plant_code,
              fta,
              format,
            },
            headers: {
              Authorization: `Bearer ${Http.accessToken}`,
            },
          }
        );

        return response?.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          return {
            ...error.response.data,
            error: true,
          };
        } else {
          throw error;
        }
      }
    },
    { mutationKey: 'exported-data', onSuccess, onError, onSettled }
  );
