import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom';
import Container from './pages/Container';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import Http from './services/http';
import { NotificationsProvider } from './components/notifications/NotificationsProvider';
import { QueryParamProvider } from 'use-query-params';
import { GlobalStateProvider } from './contexts/GlobalStateProvider';

function App() {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    logout,
  } = useAuth0();
  const [_token, setToken] = useState<string>();

  if (!isLoading && !isAuthenticated) {
    localStorage.setItem(
      'authPath',
      window.location.pathname + window.location.search
    );

    loginWithRedirect({
      appState: { target: window.location.href },
      redirectUri: `${window.location.origin}/callback`,
    });
  }

  useEffect(() => {
    const params = new URLSearchParams(document.location.search.substring(1));
    if (params.has('success')) {
      logout();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isLoading && isAuthenticated && !_token) {
      getAccessTokenSilently().then((token) => {
        Http.accessToken = token;
        setToken(token);
      });
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated]);

  const queryClient = new QueryClient();

  return !isLoading && isAuthenticated && _token && Http.accessToken ? (
    <QueryClientProvider client={queryClient}>
      <NotificationsProvider>
        <GlobalStateProvider>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={RouteAdapter}>
              <Container />
            </QueryParamProvider>
          </BrowserRouter>
        </GlobalStateProvider>
      </NotificationsProvider>
    </QueryClientProvider>
  ) : null;
}

const RouteAdapter = ({ children }: { children?: any }): any => {
  const navigate = useNavigate();
  const location = useLocation();

  const adaptedHistory = useMemo(
    () => ({
      replace(location: any) {
        navigate(location, { replace: true, state: location.state });
      },
      push(location: any) {
        navigate(location, { replace: false, state: location.state });
      },
    }),
    [navigate]
  );
  return children({ history: adaptedHistory, location });
};

export default App;
