import { FC, Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { useField, useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import axios from 'axios';
import Http from '../../../services/http';
import { useGlobalState } from '../../../contexts/GlobalStateProvider';

interface PlantCode {
  id: number;
  uuid: string;
  ClientCode: string;
  PlantCode: string;
  PlantName: string;
  created_at: string;
  updated_at: string;
}

interface Props {
  name: string;
  label: string;
}

export const PlantCodeSelect: FC<Props> = ({ name, label }) => {
  const [field, meta, helpers] = useField(name);
  const [clientCodeField, clientCodeMeta, clientCodeHelpers] =
    useField('client_code');
  const [selected, setSelected] = useState<PlantCode | null>(null);
  const { errors, touched } = useFormikContext<{ [key: string]: string }>();

  const { currentClient } = useGlobalState();

  const { data: plantCodes } = useQuery<PlantCode[]>('plantCodes', async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/plant-codes`,
      {
        headers: {
          Authorization: `Bearer ${Http.accessToken}`,
        },
      }
    );
    return data;
  });

  const displayedPlantCodes = plantCodes?.filter(
    (code) => code.ClientCode === currentClient
  );

  const displayPlantCodes = displayedPlantCodes?.length
    ? displayedPlantCodes
    : [
        {
          id: 0,
          uuid: '0',
          ClientCode: 'N/A',
          PlantCode: 'N/A',
          PlantName: 'N/A',
          created_at: '',
          updated_at: '',
        },
      ];

  useEffect(() => {
    helpers.setValue(selected?.PlantCode || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setSelected(null);
    helpers.setValue('');
    clientCodeHelpers.setValue(currentClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  return (
    <div className="col-span-1">
      <Listbox value={selected} onChange={setSelected} name={name}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {label}
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">
                  {selected
                    ? `${selected.PlantCode} - ${selected.PlantName}`
                    : 'Select a plant code'}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {displayPlantCodes.map((plantCode) => (
                    <Listbox.Option
                      key={plantCode.uuid}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={plantCode}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {`${plantCode.PlantCode} - ${plantCode.PlantName}`}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {errors[name] && touched[name] ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors[name]}
        </p>
      ) : null}
    </div>
  );
};
