import axios from 'axios';
import { useQuery } from 'react-query';
import { Tables } from '../enums/Tables';
import { ISupplier } from '../interfaces/ISupplier';
import Http from '../services/http';

export function useSingleData(table: keyof typeof Tables, uuid: string) {
  return useQuery<ISupplier>([`single_${table}`], async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API}/data/${table}/${uuid}`,
      {
        headers: {
          Authorization: `Bearer ${Http.accessToken}`,
        },
      }
    );
    return data;
  });
}
