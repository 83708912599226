export enum IssuedColumn {
  'ClientCode' = 'Client Code',
  'DatabaseCode' = 'Database Code',
  'PlantCode' = 'Plant Code',
  'PartNumber' = 'Part Number',
  'EndUse' = 'EndUse',
  'Description' = 'Description',
  'Program' = 'Program',
  'BlanketStart' = 'Blanket Start',
  'BlanketEnd' = 'Blanket End',
  'CertificateNumber' = 'Certificate Number',
  'Customer PartNumber' = 'Customer Part Number',
  'Status' = 'Status',
  'SignatureDate' = 'Signature Date',
  'Response1' = 'Response1',
  'Response2' = 'Response2',
  'Response3' = 'Response3',
  'Response4' = 'Response4',
  'Response5' = 'Response5',
  'Response6' = 'Response6',
  'Response7' = 'Response7',
  'Response8' = 'Response8',
  'Response9' = 'Response9',
  'Response10' = 'Response10',
  'CustomerName' = 'Customer Name',
  'ProgramYear' = 'Program Year',
  'id' = 'id',
}
