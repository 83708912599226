export enum SupplierColumn {
  id = 'id',
  ClientCode = 'Client Code',
  DatabaseCode = 'Database Code',
  PlantCode = 'Plant Code',
  PartNumber = 'Part Number',
  EndUse = 'EndUse',
  Description = 'Description',
  SupplierID = 'Supplier ID',
  SupplierName = 'Supplier Name',
  Program = 'Program',
  BlanketStart = 'Blanket Start',
  BlanketEnd = 'Blanket End',
  CertificateNumber = 'Certificate Number',
  Status = 'Status',
  Response1 = 'Response1',
  Response2 = 'Response2',
  Response3 = 'Response3',
  Response4 = 'Response4',
  Response5 = 'Response5',
  Response6 = 'Response6',
  Response7 = 'Response7',
  Response8 = 'Response8',
  Response9 = 'Response9',
  Response10 = 'Response10',
  ClientHTS = 'Client HTS',
  DateCompleted = 'Date Completed',
  SupplierPartNumber = 'Supplier Part Number',
  SignatureDate = 'Signature Date',
  ProgramYear = 'Program Year',
}
