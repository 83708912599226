import { FC, Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/outline';
import { ReferralRequestTypes } from '../../../enums/ReferralRequestTypes';
import { useField, useFormikContext } from 'formik';

const requestTypes = Object.keys(ReferralRequestTypes).map((key) => ({
  id: key,
  name: ReferralRequestTypes[key],
}));

interface Props {
  name: string;
  label: string;
}

export const RequestTypeSelect: FC<Props> = ({ name, label }) => {
  const [field, meta, helpers] = useField(name);
  const [selected, setSelected] = useState<{ id: string; name: string }[]>([]);
  const { errors, touched } = useFormikContext<{ [key: string]: string }>();

  useEffect(() => {
    helpers.setValue(selected?.map((x) => x.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div className="col-span-1">
      <Listbox value={selected} onChange={setSelected} name={name} multiple>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {label}
            </Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <span className="block truncate">
                  {selected.length === 0
                    ? 'Please select a request type'
                    : selected.map((x) => x.name).join(', ')}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {requestTypes.map((x, i) => (
                    <Listbox.Option
                      key={`referral-request-type-${i}`}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={x}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {x.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {errors[name] && touched[name] ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors[name]}
        </p>
      ) : null}
    </div>
  );
};
