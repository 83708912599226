import { Field, useFormikContext } from 'formik';
import { FC, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/solid';

interface Props {
  name: string;
  label: string;
  description?: string;
  classes?: string;
}

const BulkAddField: FC<Props> = ({ name, label, description, classes }) => {
  const { errors, touched } = useFormikContext<{ [key: string]: string }>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <div className={classes}>
      <div className="flex items-center">
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900 mr-2"
        >
          {label}
        </label>
        <button
          type="button"
          onClick={() => setIsDialogOpen(true)}
          className="text-gray-400 hover:text-gray-500"
        >
          <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-2">
        <Field
          id={name}
          name={name}
          component="textarea"
          rows="3"
          className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
        {errors[name] && touched[name] ? (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {errors[name]}
          </p>
        ) : null}
      </div>
      {description && (
        <p className="mt-3 text-sm leading-6 text-gray-600">{description}</p>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6">
            <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
              How to select columns from Excel
            </Dialog.Title>
            <Dialog.Description className="mt-2 text-sm text-gray-500">
              1. Open your Excel spreadsheet.
              <br />
              2. Select the columns you want to copy.
              <br />
              3. Right-click and choose 'Copy' or press Ctrl+C (Cmd+C on Mac).
              <br />
              4. Come back to this form and paste (Ctrl+V or Cmd+V) into the
              text area.
              <br />
              5. Ensure each row contains: request_type, part_number,
              part_description (in this order).
            </Dialog.Description>
            <button
              className="mt-4 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => setIsDialogOpen(false)}
            >
              Got it, thanks!
            </button>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default BulkAddField;
